import Box from '@mui/material/Box';
import HeroImage from './heroImage';

export default function HeroSection({ heroData, content }) {
  return (
    <Box
      gridColumn='1/-1'
      padding={0}
      height={1}
      marginTop={{
        xs: '-100px',
        lg: '-108px',
      }}
      data-testid='heroSection'
    >
      <Box display={{ xs: 'block', lg: 'none' }}>
        <HeroImage
          imageUrl={heroData?.imageMobile?._publishUrl}
          styles={{
            heroBox: {
              position: 'relative',
              maxHeight: '100%',
            },
          }}
          content={content}
          isLargeScreen={false}
        />
      </Box>
      <Box display={{ xs: 'none', lg: 'block' }}>
        <HeroImage
          imageUrl={heroData?.imageDesktop?._publishUrl}
          styles={{
            heroBox: {
              position: 'relative',
              maxHeight: '100%',
            },
          }}
          content={content}
        />
      </Box>
    </Box>
  );
}
