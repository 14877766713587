// TODO: error handling
export async function runAemPersistedQuery(aemClientInstance, queryPath, queryParams) {
  let queryResponse;
  if (queryParams) {
    queryResponse = await aemClientInstance.runPersistedQuery(queryPath, queryParams);
  } else {
    queryResponse = await aemClientInstance.runPersistedQuery(queryPath);
  }

  if (queryResponse === undefined || queryResponse.data === undefined) {
    return '';
  }

  return queryResponse.data;
}

export default { runAemPersistedQuery };
