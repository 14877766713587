import { Box, Typography } from '@mui/material';
import Link from 'next/link';
import { ButtonOnLight } from '../../buttons';

export default function HeroText({ content, isLargeScreen, styles = {} }) {
  const boxStyles = {
    bottom: isLargeScreen && '15%',
    top: isLargeScreen ? '40%' : '15%',
    maxWidth: isLargeScreen ? '40%' : '100%',
    pr: !isLargeScreen && '1rem',
  };

  return (
    <Box
      sx={{ position: 'absolute', left: '8%', marginBottom: '1rem', ...boxStyles, ...styles }}
      data-testid={isLargeScreen ? 'hero-text-desktop' : 'hero-text-mobile'}
    >
      <Typography variant='h0medium' display='block' color='colorTheme.contentPrimaryOnLight' gutterBottom>
        {content?.title}
      </Typography>
      <Typography variant='bodyM' display='block' color='colorTheme.contentPrimaryOnLight' gutterBottom>
        {content?.subText}
      </Typography>
      {isLargeScreen && content?.showButton && (
        <Link
          href={content?.ctaBtnLink}
          data-testid='ctaHero'
          style={{ display: 'block', marginTop: '1rem' }}
        >
          <ButtonOnLight sx={{ maxWidth: '50%' }}>{content?.ctaBtnText}</ButtonOnLight>
        </Link>
      )}
    </Box>
  );
}
