import { Box } from '@mui/material';
import Image from 'next/image';
import HeroText from './heroText';

export default function HeroImage({ imageUrl = '', styles = {}, content = {}, isLargeScreen = true }) {
  return (
    <Box
      data-testid='heroImageBox'
      sx={{
        gridColumn: '2/14',
        maxHeight: { xs: '154px', sm: '302px', lg: '663px' },
        overflow: 'hidden',
        ...styles.heroBox,
      }}
    >
      <Image
        data-testid={isLargeScreen ? 'heroImageDesktop' : 'heroImageMobile'}
        alt='Lingo Benefits'
        src={imageUrl}
        width={1640}
        height={620}
        quality={65}
        priority
        style={{
          width: '100%',
          height: 'auto',
          display: 'block',
          ...styles.heroImage,
        }}
      />
      {Object.keys(content)?.length > 0 && <HeroText content={content} isLargeScreen={isLargeScreen} />}
    </Box>
  );
}
