import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

export default function EyebrowSection({ isLargeScreen, eyebrowSectionData, styles = {} }) {
  const contentOneBoxStyles = {
    gridColumn: { xs: '1/-1', lg: '2/7' },
    gridRow: { lg: '1/2' },
    ...styles?.contentOneBox,
  };

  const dividerStyles = {
    borderWidth: '3px',
    borderColor: 'colorTheme.strokePrimaryOnDark',
    margin: '1rem 0',
    ...styles?.divider,
  };

  const contentTitleBoxStyles = {
    gridRow: { lg: '2/3' },
    gridColumn: { xs: '1/-1', lg: '2/7' },
  };

  const contentTwoBoxStyles = {
    gridColumn: { xs: '1/-1', lg: '8/13' },
    gridRow: { lg: '2/3' },
    mt: { xs: '40px', lg: '0' },
    ...styles?.contentTwoBox,
  };

  return (
    <Box
      data-testid='hero_eyebrow-section'
      sx={{
        display: 'grid',
        gridColumn: '2/-2',
        gridTemplateColumns: 'repeat(14, 1fr)',
        padding: `6.25rem 0`,
        color: 'colorTheme.contentPrimaryOnDark',
        ...styles,
      }}
    >
      <Box sx={{ ...contentOneBoxStyles }}>
        <Typography variant='bodyLWeb3'>{eyebrowSectionData.contentOne}</Typography>
        <Divider sx={{ ...dividerStyles }} />
      </Box>
      <Box sx={{ ...contentTitleBoxStyles }}>
        <Typography variant={`${isLargeScreen ? 'h1' : 'h3'}`}>{eyebrowSectionData.title}</Typography>
      </Box>
      <Box sx={{ ...contentTwoBoxStyles }}>
        <Typography variant={`${isLargeScreen ? 'bodyLWeb3' : 'bodyMWeb3'}`}>
          {eyebrowSectionData.contentTwo}
        </Typography>
      </Box>
    </Box>
  );
}
