import CustomLink from './CustomLink';

export default function CustomerSupport({ email, phoneNumber, hours, env }) {
  return (
    <>
      <CustomLink url={`mailto:${email}`} newTab={false} title={email} env={env} prefetch={false} />
      <CustomLink url={`tel:${phoneNumber}`} newTab={false} title={phoneNumber} env={env} prefetch={false} />
      <div className='text-brown-100 py-0.5'>{hours?.split(',')[0]}</div>
      <div className='text-brown-100 py-0.5'>{hours?.split(',')[1]}</div>
    </>
  );
}
