import { AEMHeadless } from '@adobe/aem-headless-client-js';

let aemHeadless = null;

export default async function aemClient(config) {
  if (!aemHeadless) {
    const { AEM_GRAPHQL_ENDPOINT, AEM_HOST_URI } = config;

    aemHeadless = new AEMHeadless({
      serviceURL: AEM_HOST_URI,
      endpoint: AEM_GRAPHQL_ENDPOINT,
      fetch,
    });
  }

  return aemHeadless;
}
