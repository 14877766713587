import aemClient from './aemClient';
import { runAemPersistedQuery } from './aemQuery';

const config = {
  AEM_GRAPHQL_ENDPOINT: process.env.APPSETTING_AEM_GRAPHQL_ENDPOINT,
  AEM_HOST_URI: process.env.APPSETTING_AEM_HOST_URI,
};

export default async function getComponentAemData(query = '', options = {}) {
  if (!query) return {};

  const aemClientInstance = await aemClient(config);
  const data = await runAemPersistedQuery(aemClientInstance, query, options);

  return data;
}
