import Box from '@mui/material/Box';
import { ApplePayCard, GooglePayCard, PaypalCard, MastercardCard, VisaCard } from '../icons';

export default function CreditCardBrand({ brand }) {
  if (brand == null) {
    return null;
  }

  let icon;

  if (brand === 'VISA') {
    icon = <VisaCard />;
  }

  if (brand === 'MASTER') {
    icon = <MastercardCard />;
  }

  if (brand === 'APPLEPAY') {
    icon = <ApplePayCard />;
  }

  if (brand === 'GOOGLEPAY') {
    icon = <GooglePayCard />;
  }

  if (brand === 'PAYPAL_CONTINUE' || brand === 'PAYPAL') {
    icon = <PaypalCard />;
  }

  return <Box>{icon}</Box>;
}
