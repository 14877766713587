import Link from 'next/link';

import getComponentAemData from 'aem/src/getComponentAemData';

import CookieConsent from '../cookieConsent';
import CustomerSupport from './CustomerSupport';
import CustomLink from './CustomLink';
import { AbbottSquareLogo, AppleStore, CeMark, UkCaMark } from '../../icons';

import './styles.scss';

export default async function Footer({ queryOptions = {}, env }) {
  const {
    multiLineCmByPath: { item: { line1 = '', line2 = '', line3 = '', line4 = '' } = {} } = {},
    splashtextByPath: { item: { normalText = '', boldText = '' } = {} } = {},
    customerSupportCmByPath: { item: { email, phoneNumber, hours } = {} } = {},
    footerLingoLinksCmList: { items: links = [] } = {},
  } = await getComponentAemData('bts-bluebirddtc/GetFooterData', queryOptions);

  const APPLE_STORE_URL = 'https://apps.apple.com/gb/app/lingo-metabolic-coaching/id1670445335';

  const categorizedLinks = links?.reduce((acc, link) => {
    if (!acc[link.category]) {
      acc[link.category] = [];
    }
    acc[link.category].push(link);
    return acc;
  }, {});

  Object.keys(categorizedLinks).forEach((category) => {
    categorizedLinks[category].sort((a, b) => a.priority - b.priority);
  });

  const {
    helpLinks = [],
    hygieneLinks = [],
    lingoLinks = [],
    partners = [],
    socialLinks = [],
  } = categorizedLinks || {};

  return (
    <div className='grid-container'>
      {/* First sub-grid: links and emails */}
      <div className='grid grid-cols-subgrid col-span-full'>
        <div className='col-span-4 md:col-span-2'>
          {lingoLinks.map((link) => (
            <CustomLink
              key={link?.title}
              url={link?.url}
              newTab={link?.newTab}
              title={link?.title}
              env={env}
            />
          ))}
        </div>
        <div className='col-span-4 md:col-span-2'>
          {helpLinks.map((link) => (
            <CustomLink
              key={link?.title}
              url={link?.url}
              newTab={link?.newTab}
              title={link?.title}
              env={env}
              prefetch={false}
            />
          ))}
        </div>
        <div className='col-span-4 md:col-span-2'>
          {socialLinks.map((link) => (
            <CustomLink
              key={link?.title}
              url={link?.url}
              newTab={link?.newTab}
              title={link?.title}
              env={env}
              prefetch={false}
            />
          ))}
        </div>
        <div className='col-span-4 md:col-span-3'>
          <CustomerSupport email={email} phoneNumber={phoneNumber} hours={hours?.plaintext} env={env} />
        </div>
        <div className='col-span-4 md:col-span-3'>
          {partners.map((partner) => (
            <CustomLink
              key={partner?.title}
              url={`mailto:${partner?.url}`}
              newTab={partner?.newTab}
              title={partner?.title}
              env={env}
              prefetch={false}
            />
          ))}
        </div>
      </div>
      {/* Second sub-grid: disclaimers */}
      <div className='grid grid-cols-subgrid col-span-full'>
        <div className='col-span-4 md:col-span-6 order-2 md:order-1 mt-4 mb-2 md:my-0'>
          <div className='h-full flex flex-col justify-start md:justify-end flex-wrap pr-4'>
            <div className='text-brown-100'>
              <div className='flex md:items-center'>
                <CeMark />
                <div className='text-brown-100 xxs opacity-50 ml-1 md:ml-2'>{line3?.plaintext}</div>
              </div>
              <div className='flex md:items-center mt-2'>
                <UkCaMark />
                <div className='text-brown-100 xxs opacity-50 ml-1 md:ml-2'>{line4?.plaintext}</div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-span-4 md:col-span-6 order-1 md:order-2'>
          <div
            className='py-2 md:py-6 md:pt-0 flex flex-col md:flex-row justify-between items-start'
            data-testid='footer-logo-wrapper'
          >
            <div className='order-1 md:order-2 mb-6 md:mb-0' data-testid='footer-apple-store'>
              <Link href={APPLE_STORE_URL}>
                <AppleStore />
              </Link>
            </div>
            <div className='order-2 md:order-1' data-testid='footer-abbott-logo'>
              <AbbottSquareLogo width={36} height={39} sx={{ order: 1 }} />
            </div>
          </div>
          {/* disclaimers */}
          <div className='pb-2'>
            <div className='text-brown-100 xs opacity-50'>{line1?.plaintext}</div>
            <div className='text-brown-100 xs opacity-50 mt-5'>{line2?.plaintext}</div>
          </div>
        </div>
      </div>
      {/* Third sub-grid: legalese and links */}
      <div className='grid grid-cols-subgrid col-span-full'>
        <div className='col-span-4 md:col-span-6 order-2 md:order-1 mt-8 md:mt-0'>
          <div className='h-full flex flex-col justify-start md:justify-end flex-wrap pr-4'>
            <div className='text-brown-100 xs mb-1'>{boldText}</div>
            <div className='text-brown-100 xs opacity-20'>{normalText}</div>
          </div>
        </div>
        <div className='col-span-4 md:col-span-6 order-1 md:order-2'>
          {' '}
          {/* hygiene links */}
          <div className='flex flex-col md:flex-row footer_hygiene-links-wrapper'>
            {hygieneLinks.map((link) => (
              <CustomLink
                key={link?.title}
                url={link?.url}
                newTab={link?.newTab}
                title={link?.title}
                className='xs'
                env={env}
              />
            ))}
          </div>
          <CookieConsent />
        </div>
      </div>
    </div>
  );
}
