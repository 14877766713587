import Link from 'next/link';
import LOCATION_PATHS from '@lingo/adobe-analytics';
import { replaceUrls } from 'utils/urls';

export default function CustomLink({ url, newTab, title, className = '', env, prefetch = true }) {
  const envUrl = replaceUrls(url, env);
  return (
    <div className='py-0.5'>
      <Link
        href={envUrl}
        target={newTab ? '_blank' : '_self'}
        key={title}
        data-analytics-action={title}
        data-analytics-location={LOCATION_PATHS.footer}
        rel='noopener noreferrer'
        prefetch={prefetch}
      >
        <span className={`text-brown-100 footer_custom-link ${className}`}>{title}</span>
      </Link>
    </div>
  );
}
