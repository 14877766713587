import Link from 'next/link';
import { Button, Typography } from '@mui/material';
import { ChevronLeft } from '../icons';

export default function BackButtonLink({ href, text }) {
  return (
    <Link href={href}>
      <Button
        variant='none'
        disableRipple
        startIcon={<ChevronLeft width='24' height='24' />}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          transition: '.5s ease',
          whiteSpace: 'nowrap',
          '&:hover': {
            transition: '.5s ease',
            marginLeft: '-10px',
            background: 'none',
          },
          borderRadius: '4px',
          pl: 0,
        }}
        aria-label='Back Button'
      >
        <Typography variant='buttonM' color='colorTheme.contentPrimaryOnLight' pl='5px'>
          {text}
        </Typography>
      </Button>
    </Link>
  );
}
